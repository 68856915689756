import { GridColDef } from '@mui/x-data-grid-pro';
import CopyButton from 'components/buttons/CopyButton';
import InviteeFullNameRowCell from './_components/InviteeFullNameRowCell';

export const columns: GridColDef[] = [
  {
    field: 'connectionDate',
    headerName: 'Date',
    width: 125,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'inviterFullName',
    headerName: 'Inviter Full Name',
    width: 160,
    renderCell: (params: any) => {
      return <div>{params.row.inviterFirstName + ' ' + params.row.inviterLastName}</div>;
    },
  },
  {
    field: 'inviteeFullName',
    headerName: 'Invitee Full Name',
    width: 160,
    renderCell: (params: any) => {
      return <InviteeFullNameRowCell params={params} />;
    },
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 325,
    renderCell: (params: any) => {
      const {
        address,
        city,
        state,
        zip,
      } = params.row;
      return <div>{`${address}, ${city} ${state}, ${zip}`}</div>;
    },
  },
  {
    field: 'connectionStatus',
    headerName: 'Status',
    width: 200,
    renderCell: (params: any) => {
      return <div>{params.value}</div>;
    },
  },
  {
    field: 'contactEmail',
    headerName: 'Invitee Email',
    filterable: true,
    width: 200,
    renderCell: (params: any) => {
      if (!!params.row.noEmailAvailable) {
        return <div>NO EMAIL AVAILABLE</div>;
      }

      return <div>{params.row.contactEmail}</div>;
    },
  },
  {
    field: 'noEmailAvailable',
    headerName: 'No Email Available',
    width: 50,
    hide: true,
    valueGetter: (params: any) => {
      return !!params.row.noEmailAvailable;
    },
  },
  {
    field: 'dataQualityTag',
    headerName: 'Data Quality',
    width: 150,
  },
  {
    field: 'connectionId',
    headerName: 'Actions',
    width: 100,
    renderCell: (params: any) => {
      return <CopyButton message={params.row.connectionId} infoToastMessage='Connection ID copied to clipboard' />;
    },
  },
];
