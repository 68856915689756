
import { Grid, Tab, Tabs } from '@mui/material';
import TabPanel from 'components/tabs/SimpleTabPanel';

import PageHeader from 'components/SectionHeaders/PageHeader';
import { useState } from 'react';
import AuditCustomerUploadForm from './auditCustomerUpload/AuditCustomerUploadForm';
import Migrations from './migrations/Migrations';
import ReferrerRewardTestingForm from './referrerRewardTesting/ReferrerRewardTestingForm';
import TrendingServiceTestForm from './trendingService/TrendingServiceTestForm';

function a11yProps(index: number) {
  return {
    'id': `price-style-tab-${index}`,
    'aria-controls': `price-tabpanel-${index}`,
  };
}

export default function MigrationsAndProcesses() {

  const [tab, setTab] = useState<number>(0);

  return (
    <Grid container spacing={2} className='pageGridContainer'>
      <Grid item xs={12}>
        <PageHeader headerText={'Migrations and Processes'} />
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setTab(newValue);
          }}
          aria-label="price or no price tabs"
        >
          <Tab label="Migrations" {...a11yProps(0)} />
          <Tab label="Audit Customer Upload" {...a11yProps(1)} />
          <Tab label="Referrer Reward Testing" {...a11yProps(2)} />
          <Tab label="Trending Service" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={tab} index={0} sx={{ width: '100%' }}>
          <Grid container item xs={12} spacing={1}>
            <Migrations/>
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <AuditCustomerUploadForm/>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ReferrerRewardTestingForm/>
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <TrendingServiceTestForm/>
        </TabPanel>
      </Grid>
    </Grid>
  );
}