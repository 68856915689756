import { Grid, Button } from '@mui/material';
import { sendAutomatedNewsletters, manuallySendNeighborhoodNotifications, manuallyTriggerSendGridBounces } from 'api/communicationsApi';
import {
  syncHistoricalSubscriberDataToAlgolia,
  syncHistoricalCustomerDataToAlgolia,
  migrateServiceProviderHasReviews,
  reconcileCustomersWithMissingServiceOfferingIds,
  migrateSubscriberProviderUploadedField,
  migrateServiceProviderWithMarketId,
  migrateNeighborhoods,
  migrateServiceProviderNameOnCustomers,
  migrateServiceProviderNameOnSubscribers,
  migrateServiceTypes,
  migrateLocalServiceDate,
  syncNeighborhoodsToAlgolia,
  updateAllNsosToSetServiceTypeIndex,
  migrateCoverageAreasOnServiceOfferings,
  syncServicetypesToAlgolia,
  syncHistoricalServiceSummaryDataToAlgolia,
  migrateSeededCustomers,
  findLatLngsForIndividuals,
  resetOGImages,
  sendTestProxiedMessage,
  correctEndingBalanceOnSubtractAudits,
  deleteTestUserConversations,
  trendingServiceTest,
} from 'api/migrationApi';
import { manuallySendCustomerFollowUpReviews, manuallySendSubscriberReviews } from 'api/reviewsApi';
import {
  historicalSearchSync as historicalServiceProvidersSearchSync,
  manuallySendDailyReminders,
} from 'api/serviceProviderApi';

export default function Migrations() {

  //testing

  //manual sends
  async function onSendAutomatedNewsletters() {
    await sendAutomatedNewsletters();
  }

  async function onManuallySendNeighborhoodNotifications() {
    await manuallySendNeighborhoodNotifications('EASTERN');
  }


  async function onHistoricalServiceProviderSearchSync() {
    await historicalServiceProvidersSearchSync();
  }

  async function onHistoricalNeighborhoodsSearchSync() {
    await syncNeighborhoodsToAlgolia();
  }

  async function onManuallySendDailyReminders() {
    await manuallySendDailyReminders();
  }

  async function onSyncHistoricalSubscriberDataToAlgolia() {
    await syncHistoricalSubscriberDataToAlgolia();
  }

  async function onSyncHistoricalCustomerDataToAlgolia() {
    await syncHistoricalCustomerDataToAlgolia();
  }

  async function onMigrationServiceProviderHasReviews() {
    await migrateServiceProviderHasReviews();
  }

  async function onReconcileCustomersWithMissingServiceOfferingIds() {
    await reconcileCustomersWithMissingServiceOfferingIds();
  }

  async function onTesting() {

  }

  async function onMigrateSubscriberProviderUploadedField() {
    await migrateSubscriberProviderUploadedField();
  }

  async function onMigrateServiceProviderWithMarketId() {
    await migrateServiceProviderWithMarketId();
  }

  async function onMigrateServiceProviderNameOnCustomers() {
    await migrateServiceProviderNameOnCustomers();
  }

  async function onMigrateServiceProviderNameOnSubscribers() {
    await migrateServiceProviderNameOnSubscribers();
  }

  async function onManuallySendSubscriberReviewRequests() {
    await manuallySendSubscriberReviews();
  }

  async function onManuallySendCustomerReviewRequestFollowups() {
    await manuallySendCustomerFollowUpReviews();
  }

  async function onMigrateServiceTypes() {
    await migrateServiceTypes();
  }

  async function onMigrateLocalServiceDate() {
    await migrateLocalServiceDate();
  }


  async function onMigrateSeededCustomers() {
    await migrateSeededCustomers();
  }

  async function onMigrateNeighborhoods() {
    await migrateNeighborhoods();
  }

  async function onResetOGImages() {
    await resetOGImages();
  }

  async function onFindLatLngsForIndividuals() {
    await findLatLngsForIndividuals();
  }


  async function onUpdateAllNsosToSetServiceTypeIndex() {
    await updateAllNsosToSetServiceTypeIndex();
  }

  async function onMigrateCoverageAreasOnServiceOfferings() {
    await migrateCoverageAreasOnServiceOfferings();
  }

  async function onCorrectEndingBalanceOnSubtractAudits() {
    await correctEndingBalanceOnSubtractAudits();
  }

  async function onDeleteTestUserConversations() {
    await deleteTestUserConversations();
  }


  async function onSyncHistoricalServiceTypesToAlgolia() {
    await syncServicetypesToAlgolia();
  }

  async function onSyncHistoricalServiceSummaryDataToAlgolia() {
    await syncHistoricalServiceSummaryDataToAlgolia();
  }

  async function onSendTestProxiedMessage() {
    await sendTestProxiedMessage();
  }

  async function onProcessSendGridRecentBounces() {
    await manuallyTriggerSendGridBounces();
  }


  return (
    <>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onTesting}
        >
          <span>Testing</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onSendAutomatedNewsletters}
        >
          <span>Send Automated Newsletters</span>
        </Button>

      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrationServiceProviderHasReviews}
        >
          <span>Migrate Service Provider has reviews</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onReconcileCustomersWithMissingServiceOfferingIds}
        >
          <span>Reconcile customers with missing service offerings</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onHistoricalServiceProviderSearchSync}
        >
          <span>Sync All Service Providers to Algolia</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onHistoricalNeighborhoodsSearchSync}
        >
          <span>Sync All Neighborhoods to Algolia</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onManuallySendDailyReminders}
        >
          <span>Manually send daily reminders</span>
        </Button>
      </Grid>


      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onSyncHistoricalSubscriberDataToAlgolia}
        >
          <span>Sync Subscriber data to Algolia</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onSyncHistoricalCustomerDataToAlgolia}
        >
          <span>Sync Customer data to Algolia</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateSubscriberProviderUploadedField}
        >
          <span>Migrate Subscribers</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateServiceProviderWithMarketId}
        >
          <span>Migrate Service Provider with Market Id</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateServiceProviderNameOnCustomers}
        >
          <span>Migrate Service Provider Name on Customers</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateServiceProviderNameOnSubscribers}
        >
          <span>Migrate Service Provider Name on Subscribers</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onManuallySendSubscriberReviewRequests}
        >
          <span>Manually trigger subscriber review requests</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onManuallySendCustomerReviewRequestFollowups}
        >
          <span>Manually trigger customer review request followups</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateServiceTypes}
        >
          <span>Migrate Service Types</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateLocalServiceDate}
        >
          <span>Migrate Local Service Date on NSOIs</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onUpdateAllNsosToSetServiceTypeIndex}
        >
          <span>Migrate service type sk on NSOs</span>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateCoverageAreasOnServiceOfferings}
        >
          <span>Migrate coverage area on service offerings</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateSeededCustomers}
        >
          <span>Migrate seeded customers</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onMigrateNeighborhoods}
        >
          <span>Migrate neighborhoods (sync dynamo and postgres)</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onResetOGImages}
        >
          <span>Reset OG Images</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onFindLatLngsForIndividuals}
        >
          <span>Migrate LatLngs for Individuals</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onSendTestProxiedMessage}
        >
          <span>Send Test Proxied SMS Message</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onDeleteTestUserConversations}
        >
          <span>Delete test user conversations</span>
        </Button>
      </Grid>

      <Grid item xs={12} sx={{ margin: '20px' }}></Grid>
      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onSyncHistoricalServiceTypesToAlgolia}
        >
          <span>Sync Service Types to Algolia</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onSyncHistoricalServiceSummaryDataToAlgolia}
        >
          <span>Sync Service Summary Data to Algolia</span>
        </Button>
      </Grid>

      <Grid item xs={12} sx={{ paddingBottom: '100px' }}>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onManuallySendNeighborhoodNotifications}
        >
          <span>Send Neighborhood Activity Notifications (MANUAL)</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={onProcessSendGridRecentBounces}
        >
          <span>Process SendGrid Recent Bounces</span>
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          variant='outlined'
          onClick={correctEndingBalanceOnSubtractAudits}
        >
          <span>Correct Ending Balance on SUBTRACT service credit audits</span>
        </Button>
      </Grid>
    </>
  );
}