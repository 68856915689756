import { EditButton } from 'components/buttons';
import useModal from 'components/modal/useModal';
import { modalStoreKey } from './EditInviteeNameModal';

export default function InviteeFullNameRowCell({ params }) {
  const { openModal } = useModal(modalStoreKey);
  return <>
    <div>{params?.row?.inviteeFirstName + ' ' + params?.row?.inviteeLastName}</div>
    <EditButton onClick={() => {
      openModal({ connectionRecord: params.row });
    }} tooltipTitle='Edit Name' />
  </>;
}