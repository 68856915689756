import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { NeighborConnectionQualityTag } from 'model/neighborConnections';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function NeighborConnectionQualityTagSelect() {
  const formCtx = useFormContext();
  const [qualityTags] = useState<any[]>(NeighborConnectionQualityTag.list);
  const { isError, getErrorMessage } = useErrorState(formCtx);
  return (
    <FormControl variant='standard' fullWidth error={isError('qualityTag')}>
      <InputLabel htmlFor="qualityTag-select">Quality Tag</InputLabel>
      <HookSelect
        control={formCtx.control}
        name='dataQualityTag'
        id='qualityTag-select'
        menuItems={qualityTags}
      />
      <FormHelperText>{getErrorMessage('qualityTag')}</FormHelperText>
    </FormControl>
  );
}