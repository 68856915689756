import { modalStoreKey } from './SendInvitesModal';
import { modalStoreKey as markNoEmailModalStoreKey } from './MarkNoEmailAvailableModal';
import { modalStoreKey as setQualityTagModalStoreKey } from './SetQualityTagModal';
import useInviteNeighborsHookStore from '../inviteNeighborsHookStore';
import { Button, Grid, Typography } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useModal from 'components/modal/useModal';

export default function SelectedRowActionButtons() {
  const { selected } = useInviteNeighborsHookStore();
  const { openModal: openSendInvitesModal } = useModal(modalStoreKey);
  const { openModal: openMarkNoEmailModal } = useModal(markNoEmailModalStoreKey);
  const { openModal: openSetQualityTagModal } = useModal(setQualityTagModalStoreKey);

  var areButtonsDisabled = !selected || selected.length === 0;

  return (
    <>
      <Grid container display={'flex'} justifyContent={'flex-start'} columnSpacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={'bold'}>Bulk Actions</Typography>
        </Grid>
        <Grid item>
          <PrimaryButton
            disabled={areButtonsDisabled}
            onClick={() => {
              openSendInvitesModal({ connectionIds: selected });
            }}
            color='error'
          >
            {areButtonsDisabled ? 'Send Invites' : `Send Invites (${selected.length})`}
          </PrimaryButton>
        </Grid>
        <Grid item>
          <Button
            disabled={areButtonsDisabled}
            variant="contained"
            color='warning'
            onClick={() => {
              openMarkNoEmailModal({ connectionIds: selected });
            }}
          >
            { areButtonsDisabled ? 'Mark as No Email Available' : `Mark as No Email Available (${selected.length})`}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={areButtonsDisabled}
            variant="contained"
            color='secondary'
            onClick={() => {
              openSetQualityTagModal({ connectionIds: selected });
            }}
          >
            { areButtonsDisabled ? 'Set Quality Tag' : `Set Quality Tag (${selected.length})`}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}