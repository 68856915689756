
import { trendingServiceTest } from 'api/migrationApi';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { ITrendingServiceTestForm } from 'model/trending_service_test/trending_service_test';
import { useEffect, useState } from 'react';

type TrendingServiceTest = {

}
const loadingKey = 'TrendingServiceTest';

const { get, update, registerListener, unregisterListener } = createStore<TrendingServiceTest>('TrendingServiceTest', {

});

export default function useTrendingServiceTest() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);

  const { createInfoToast, createSuccessToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    update({
      ...get(),
    });
    doneLoading(300);
  }

  async function onSubmit(form: ITrendingServiceTestForm) {
    onLoading();
    try {
      const res = await trendingServiceTest(form);
      createSuccessToast('request received.');
    } catch (err:any) {
      createErrorToast(err.response.data.message);
    }
    doneLoading(300);
  }


  return {
    loadingKey,
    ...get(),
    init,
    onSubmit,
  };
}