import Axios, { AxiosResponse } from 'axios';
import {
  IRetrieveNeighborConnectionsRequest,
  IRetrieveNeighborConnectionsResponse,
  ISendNeighborConnectionInviteForConsumerRequest,
  ISendNeighborConnectionInviteForConsumerResponse,
  INeighborConnectionBatchJobRequest,
  INeighborConnectionBatchJobResponse,
  INeighborConnectionSetQualityTagRequest,
  IPatchNeighborConnectionRequest,
  IPatchNeighborConnectionResponse,
} from 'model/neighborConnections';


const apiUrl = window.REACT_APP_BASE_API_URI;

export function sendNeighborConnectionInviteForConsumer(data: ISendNeighborConnectionInviteForConsumerRequest):Promise<AxiosResponse<ISendNeighborConnectionInviteForConsumerResponse>> {
  return Axios.post(`${apiUrl}/neighborConnections/v2/sendNeighborConnectionInviteForConsumer`, data);
}

export function retrieveNeighborConnections(data: IRetrieveNeighborConnectionsRequest):Promise<AxiosResponse<IRetrieveNeighborConnectionsResponse>> {
  return Axios.post(`${apiUrl}/neighborConnections/v2/searchNeighborConnections`, data);
}

export function sendInvitesToNeighborConnections(data: INeighborConnectionBatchJobRequest):Promise<AxiosResponse<INeighborConnectionBatchJobResponse>> {
  return Axios.post(`${apiUrl}/neighborConnections/v2/sendNeighborConnectionInvites`, data);
}

export function markNeighborConnectionsAsNoEmailAvailable(data: INeighborConnectionBatchJobRequest):Promise<AxiosResponse<INeighborConnectionBatchJobResponse>> {
  return Axios.post(`${apiUrl}/neighborConnections/v2/setNeighborConnectionsAsNoEmailAvailable`, data);
}


export function setQualityTagOnNeighborConnections(data: INeighborConnectionSetQualityTagRequest):Promise<AxiosResponse<INeighborConnectionBatchJobResponse>> {
  return Axios.post(`${apiUrl}/neighborConnections/v2/setNeighborConnectionsQualityTag`, data);
}

export function patchNeighborConnection(id: string, data: IPatchNeighborConnectionRequest):Promise<AxiosResponse<IPatchNeighborConnectionResponse>> {
  return Axios.patch(`${apiUrl}/neighborConnections/v2/neighborConnection/${id}`, data);
}