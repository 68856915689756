import { Grid } from '@mui/material';
import { patchNeighborConnection } from 'api/neighborConnectionsApi';
import Loading, { useLoading } from 'components/Layout/Loading';
import GenericModal from 'components/modal/GenericModal';
import useModal, { IModal } from 'components/modal/useModal';

import { HookTextField } from 'components/reactHookForm';
import useToast from 'components/toast/useToast';
import { INeighborConnectionAdminDTO, IPatchNeighborConnectionRequest } from 'model/neighborConnections';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const modalStoreKey = 'EditInviteeNameModal';
const loadingKey = 'EditInviteeNameModalLoader';

export interface IEditInviteeNameModal extends IModal {
  connectionRecord: INeighborConnectionAdminDTO;
}

export function createInitialEditInviteeNameModal() :IEditInviteeNameModal {
  return {
    open: false,
    connectionRecord: {} as INeighborConnectionAdminDTO,
  };
}

export function createEmptyEditInviteeNameModal() :IEditInviteeNameModal {
  return {
    open: false,
    connectionRecord: {} as INeighborConnectionAdminDTO,
  };
}

export default function EditInviteeNameModal() {
  const { closeModal, getModalState } = useModal(modalStoreKey);
  const state = getModalState() as IEditInviteeNameModal;
  const { connectionRecord } = state;
  const { createErrorToast, createSuccessToast, createWarnToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const [requesting, setRequesting] = useState(false);

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      connectionFirstName: '',
      connectionLastName: '',
    },
  });

  useEffect(() => {
    formContext.reset({
      connectionFirstName: connectionRecord?.inviteeFirstName,
      connectionLastName: connectionRecord?.inviteeLastName,
    });
  }, [connectionRecord]);

  const onSubmit = async (data:any) => {
    setRequesting(true);
    onLoading();
    try {
      const formData = formContext.getValues();
      const request = {} as IPatchNeighborConnectionRequest;

      if (!!formData.connectionFirstName && formData.connectionFirstName !== connectionRecord.inviteeFirstName) {
        request.connectionFirstName = formData.connectionFirstName;
      }

      if (!!formData.connectionLastName && formData.connectionLastName !== connectionRecord.inviteeLastName) {
        request.connectionLastName = formData.connectionLastName;
      }

      const resp = await patchNeighborConnection(connectionRecord.connectionId, request);

      if (resp.data.updated) {
        createSuccessToast('Successfully updated connection');
      } else {
        createWarnToast('No changes were made');
      }
    } catch (error:any) {
      console.error(error);
      createErrorToast('Error updating connections: ' + error.response?.data?.message);
    }

    doneLoading();
    setRequesting(false);
    closeModal(createEmptyEditInviteeNameModal());
  };

  return (
    <GenericModal
      modalStoreKey={modalStoreKey}
      title={'Edit Invitee Name'}
      modalContent={
        (
          <>
            <Loading loadingKey={loadingKey}>
              <Grid container justifyContent={'flex-start'}>
                <FormProvider {...formContext}>
                  <form onSubmit={formContext.handleSubmit(onSubmit)}>
                    <Grid container item justifyContent={'flex-start'} flexDirection={'column'}>
                      <Grid item xs={12}>
                        <HookTextField
                          name='connectionFirstName'
                          label='First Name'
                          required
                        />
                        {/* <Typography variant='body1'>{`Would you like to mark ${connectionIds.length} as 'no email available'?`}</Typography> */}
                      </Grid>
                      <Grid item xs={12}>
                        <HookTextField
                          name='connectionLastName'
                          label='Last Name'
                          required
                        />
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Loading>
          </>
        )
      }
      onConfirm={onSubmit}
      onSecondaryAction={() => closeModal(createEmptyEditInviteeNameModal())}
      primaryButtonText='Save'
      primaryDisabled={requesting}
      secondaryButtonText='Cancel'
    />
  );
}