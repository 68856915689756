
import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import useToast from 'components/toast/useToast';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HookTextField } from 'components/reactHookForm';
import useTrendingServiceTest from './useTrendingServiceTest';
import { ITrendingServiceTestForm } from 'model/trending_service_test/trending_service_test';

export default function TrendingServiceTestForm() {

  const { onSubmit } = useTrendingServiceTest();
  const { createErrorToast } = useToast();
  const formContext = useForm<ITrendingServiceTestForm>({
    mode: 'onTouched',
    defaultValues: {
      nsoiId: '',
      neighborhoodId: '',
    },
  });
  const onSubmitPressed : SubmitHandler<ITrendingServiceTestForm> = data => {
    onSubmit(data).catch((err:any) => {
      createErrorToast(err.response.data.message);
    });
  };


  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(onSubmitPressed)}>
          <Grid container spacing={1} >
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <HookTextField
                  required
                  name='neighborhoodId'
                  label='Neighborhood ID'
                />
                <HookTextField
                  required
                  name='nsoiId'
                  label='NSOI ID'
                />
              </Grid>
            </Grid>
            <Grid item xs={11} sx={{ marginTop: '20px' }}>
              <PrimaryButton type='submit'>Submit</PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}