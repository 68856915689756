import createStore from 'hooks/hookStore';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useState, useEffect } from 'react';
import { createEmptyIndividual } from 'model/individual';
import { INeighborConnectionAdminDTO, IRetrieveNeighborConnectionsRequest } from 'model/neighborConnections';
import { retrieveNeighborConnections } from 'api/neighborConnectionsApi';

const loadingKey = 'InviteNeighborsHookStore';

export interface INeighborConnectionsFiltersForm {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  status: string | null | undefined;
}

export const createEmptyNeighborConnectionsFiltersForm = ():INeighborConnectionsFiltersForm => {
  return {
    startDate: null,
    endDate: null,
    status: null,
  };
};

type InviteNeighborsHookStore = {
  filtersForm: INeighborConnectionsFiltersForm;
  neighborConnections: INeighborConnectionAdminDTO[];
  selected: any[];
}


const { get, update, registerListener, unregisterListener } = createStore<InviteNeighborsHookStore>('InviteNeighborsHookStore', {
  filtersForm: createEmptyNeighborConnectionsFiltersForm(),
  neighborConnections: [],
  selected: [],
});

export default function useInviteNeighborsHookStore() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      let individual = createEmptyIndividual();
      update({
        ...get(),
        // individual: individual,
      });
    } catch (error) {
      doneLoading(300);
    }
    doneLoading(300);
  }

  function clearSearch() {
    update({
      ...get(),
      filtersForm: {
        startDate: null,
        endDate: null,
        status: null,
      },
    });
  };

  async function onSearch(formValues:INeighborConnectionsFiltersForm) {
    onLoading();
    try {
      var { startDate, endDate, status } = formValues;
      var endDateInclusive = new Date(endDate as string);
      endDateInclusive.setHours(23, 59, 59, 999);

      var request:IRetrieveNeighborConnectionsRequest = {
        startDate: startDate,
        endDate: endDateInclusive.toISOString(),
        status: status,
      };

      var resp = await retrieveNeighborConnections(request);

      if (!!resp?.data?.neighborConnections) {
        update({
          ...get(),
          neighborConnections: resp.data.neighborConnections,
        });
      }
    } catch (error) {
      createErrorToast('Error searching for neighbor connections: ' + error);
      console.error(error);
    }
    doneLoading(300);
  }

  function setSelected(selected:any[]) {
    update({
      ...get(),
      selected: selected,
    });
  }

  return {
    ...get(),
    clearSearch,
    init,
    loadingKey,
    onSearch,
    setSelected,
  };
}
