import { Grid, Button } from '@mui/material';
import useInviteNeighborsHookStore, { createEmptyNeighborConnectionsFiltersForm, INeighborConnectionsFiltersForm } from './inviteNeighborsHookStore';
import HookDatePicker from 'components/reactHookForm/HookDatePicker';
import { FormProvider, useForm } from 'react-hook-form';
import NeighborConnectionStatusSelect from './_components/NeighborConnectionStatusSelect';
import { useState } from 'react';
import { useLoading } from 'components/Layout/Loading';

export default function InviteNeighborsFilterBar() {
  const [requesting, setRequesting] = useState(false);
  const {
    onSearch,
    loadingKey,
  } = useInviteNeighborsHookStore();

  const formContext = useForm<INeighborConnectionsFiltersForm>({
    mode: 'onChange',
    defaultValues: createEmptyNeighborConnectionsFiltersForm(),
  });

  const onSubmit =async () => {
    setRequesting(true);
    onLoading();
    try {
      await onSearch(formContext.getValues());
    } catch (e:any) {
      console.error(e);
    }
    setRequesting(false);
    doneLoading();
  };
  const { onLoading, doneLoading } = useLoading(loadingKey);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            columnSpacing={4}
            display={'flex'}
            justifyContent={'start'}
            style={{ marginBottom: '20px' }}>
            <Grid item>
              <HookDatePicker
                label="Start Date"
                name="startDate"
                dateFormats={{
                  fullDate: 'yyyy-MM-dd',
                }}
                required
              />
            </Grid>
            <Grid item >
              <HookDatePicker
                label="End Date"
                name="endDate"
                required
                dateFormats={{
                  fullDate: 'yyyy-MM-dd',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <NeighborConnectionStatusSelect />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                color="primary"
                type='submit'
                fullWidth
                disabled={requesting}
              >
                    Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}